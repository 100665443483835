import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'
import { allDeliveryMen } from 'app/mocks/data/users/deliveryMen'

import { api } from 'app/utils/constants'

export const getDeliveryMan = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.deliveryMen.DETAILS}`,
    ({ params }) => {
        const { id } = params

        const deliveryManFound = allDeliveryMen.find(
            (deliveryMan) => deliveryMan.id.toString() === id,
        )

        // Not found
        if (!deliveryManFound)
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })

        return HttpResponse.json(deliveryManFound)
    },
)
