import { http, HttpResponse } from 'msw'
import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'

import { allTechnicians } from 'app/mocks/data/users/technicians'

export const getAllTechnicians = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.technicians.LIST}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        const managersIdsFilter = url.searchParams.get('client__id__in')
        const lastNameFilter = url.searchParams.get('last_name__icontains')
        const firstNameFilter = url.searchParams.get('first_name__icontains')
        const cardNumberFilter = url.searchParams.get('card_id__icontains')
        const mifareCodeFilter = url.searchParams.get(
            'serial_number__icontains',
        )

        let allTechniciansFiltered = allTechnicians

        // Manager filter
        if (!!managersIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managersIdsFilter)

            allTechniciansFiltered = allTechniciansFiltered.filter(
                (technician) =>
                    technician.client.some((manager) =>
                        managerIdsSplit.includes(manager.id),
                    ),
            )
        }

        // Last name filter
        if (!!lastNameFilter) {
            allTechniciansFiltered = allTechniciansFiltered.filter(
                (technician) =>
                    technician.last_name
                        .toLowerCase()
                        .includes(lastNameFilter.toLowerCase()),
            )
        }

        // First name filter
        if (!!firstNameFilter) {
            allTechniciansFiltered = allTechniciansFiltered.filter(
                (technician) =>
                    technician.first_name
                        .toLowerCase()
                        .includes(firstNameFilter.toLowerCase()),
            )
        }

        // Card number filter
        if (!!cardNumberFilter) {
            allTechniciansFiltered = allTechniciansFiltered.filter(
                (technician) =>
                    technician.card_id
                        .toLowerCase()
                        .includes(cardNumberFilter.toLowerCase()),
            )
        }

        // MIFARE code filter
        if (!!mifareCodeFilter) {
            allTechniciansFiltered = allTechniciansFiltered.filter(
                (technician) =>
                    technician.serial_number.includes(mifareCodeFilter),
            )
        }

        // Pagination
        const limitArray =
            allTechniciansFiltered.length > limit
                ? offset + limit
                : allTechniciansFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allTechniciansFiltered.slice(offset, limitArray)
                : allTechniciansFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allTechniciansFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allTechniciansFiltered.length,
            next,
            results,
        })
    },
)
