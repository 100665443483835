export const api = {
    ALLERGENS: 'allergens/',
    EMAIL_PARAMETERS: 'email_parameters/',
    MACHINES_CATEGORIES: 'categories_machines/',
    PRODUCTS_CATEGORIES: 'categories_products/',
    PRODUCTS_SUB_CATEGORIES: 'sub_categories_products/',
    PRODUCTS_IMPORT: 'import/products/',
    SITES_TYPES: 'site_types/',
    STATS_STOCK: 'stats_stock',
    TICKET_PARAMETERS: 'ticket_parameters/',
    UPLOAD_SIGNATURE: 'images_sign_upload/',
    ZONES: 'zones/',

    auth: {
        ME: 'me',
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
    },

    machines: {
        ALL: 'machines/',
        MICROWAVES: 'machines/microwaves',
        PLANOGRAMS: 'machines_grid_items/',

        terminals: {
            REBOOT: 'machines/terminals/:id/reboot',
        },
    },

    park: {
        CLIENTS: 'site_customer/',
        DIAGNOSTIC: 'diagnostic/',
        MACHINE_RELATIONS: 'machines_grid/',
        PLANOGRAMS: 'machines_grid_items/',
        SITES: 'sites/',
    },

    products: {
        PRODUCTS: 'products/',
        SUPPLIERS: 'supplier_products/',
    },

    transactions: {
        BLOCKED_CARDS: 'blocked_cards/',
        SALES_OLD: 'sales/',
        SALES: 'transactions/sales',
    },

    users: {
        KONSOLE: 'users/',
        KONSOLE_IMPERSONATE: 'users/impersonate/',
        PERMISSIONS: 'users_permissions/',
        SERVICES: 'users/services/',

        deliveryMen: {
            DETAILS: 'deliverymen/:id/',
            LIST: 'deliverymen/',
        },

        konvives: {
            CREATION_LINK: 'users/konvives/creation-link',
            IMPORT: 'machines_users/import/',
        },

        managers: {
            LIST: 'clients/',

            categories: {
                LIST: 'client_category',
            },
        },

        profiles: {
            DETAILS: 'users_groups/:id/',
            LIST: 'users_groups/',
        },

        technicians: {
            DETAILS: 'technicians/:id/',
            LIST: 'technicians/',
        },
    },
}
