import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'
import { allDeliveryMen } from 'app/mocks/data/users/deliveryMen'

import { api } from 'app/utils/constants'

export const updateDeliveryMan = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.deliveryMen.DETAILS}`,
    async ({ params, request }) => {
        const data = await request.json()
        const { id } = params

        const deliveryManFound = allDeliveryMen.find(
            (deliveryMan) => deliveryMan.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!deliveryManFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const deliveryManUpdated = {
            ...deliveryManFound,
            ...data,
        }

        return HttpResponse.json(deliveryManUpdated)
    },
)
