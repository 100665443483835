import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'
import { allTechnicians } from 'app/mocks/data/users/technicians'

export const updateTechnician = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.technicians.DETAILS}`,
    async ({ params, request }) => {
        const { id } = params
        const data = await request.json()

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        const technicianFound = allTechnicians.find(
            (technician) => technician.id.toString() === id,
        )

        // Not found
        if (!technicianFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const technicianUpdated = {
            ...technicianFound,
            ...data,
        }

        return HttpResponse.json(technicianUpdated)
    },
)
