import { createTechnician } from './createTechnician'
import { deleteTechnician } from './deleteTechnician'
import { getAllTechnicians } from './getAllTechnicians'
import { getTechnician } from './getTechnician'
import { updateTechnician } from './updateTechnician'

export const technicians = [
    createTechnician,
    deleteTechnician,
    getAllTechnicians,
    getTechnician,
    updateTechnician,
]
