import { categories } from './categories'
import { deliveryMen } from './deliveryMen'
import { managers } from './managers'
import { permissions } from './permissions'
import { profiles } from './profiles'
import { services } from './services'
import { technicians } from './technicians'
import { usersKonsole } from './konsole'
import { usersKonvives } from './konvives'

export const users = [
    ...categories,
    ...deliveryMen,
    ...managers,
    ...permissions,
    ...profiles,
    ...services,
    ...technicians,
    ...usersKonsole,
    ...usersKonvives,
]
