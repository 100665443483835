import * as managers from 'app/mocks/data/users/managers/list'

export const technician1 = {
    id: 1,
    client: [managers.manager1],
    last_name: 'PAUL',
    first_name: 'Doug',
    serial_number: '0987390',
    card_id: 'ADA242',
}

export const technician2 = {
    id: 2,
    client: [managers.manager2, managers.manager5],
    last_name: 'DUPONT',
    first_name: 'Alexandre',
    serial_number: '99873452',
    card_id: 'BNCVZE0456',
}

export const technician3 = {
    id: 3,
    client: [managers.manager3],
    last_name: 'MARTIN',
    first_name: 'Sophie',
    serial_number: '88612347',
    card_id: 'CZXSRT0123',
}

export const technician4 = {
    id: 4,
    client: [managers.manager1, managers.manager7],
    last_name: 'BERNARD',
    first_name: 'Claire',
    serial_number: '77654321',
    card_id: 'DYTMKL7645',
}

export const technician5 = {
    id: 5,
    client: [managers.manager8],
    last_name: 'LECLERC',
    first_name: 'Lucas',
    serial_number: '56783920',
    card_id: 'PLOKJH0987',
}

export const technician6 = {
    id: 6,
    client: [managers.manager2, managers.manager6, managers.manager9],
    last_name: 'ROUX',
    first_name: 'Emma',
    serial_number: '34567819',
    card_id: 'UIOPNM2345',
}

export const technician7 = {
    id: 7,
    client: [managers.manager4],
    last_name: 'FOURNIER',
    first_name: 'Hugo',
    serial_number: '23456781',
    card_id: 'JKLZXC5432',
}

export const technician8 = {
    id: 8,
    client: [managers.manager11, managers.manager15],
    last_name: 'MOREAU',
    first_name: 'Léa',
    serial_number: '12345678',
    card_id: 'WERQAS1243',
}

export const technician9 = {
    id: 9,
    client: [managers.manager19],
    last_name: 'GARCIA',
    first_name: 'Arthur',
    serial_number: '87654321',
    card_id: 'YUIOPLK7654',
}

export const technician10 = {
    id: 10,
    client: [managers.manager12, managers.manager22],
    last_name: 'PETIT',
    first_name: 'Jules',
    serial_number: '98765432',
    card_id: 'ZXCVBNM5678',
}

export const technician11 = {
    id: 11,
    client: [managers.manager5, managers.manager10],
    last_name: 'DURAND',
    first_name: 'Zoé',
    serial_number: '34567891',
    card_id: 'QWERTYU6543',
}

export const technician12 = {
    id: 12,
    client: [managers.manager3, managers.manager7],
    last_name: 'LAMBERT',
    first_name: 'Sarah',
    serial_number: '65783921',
    card_id: 'HJUIKLO9856',
}

export const technician13 = {
    id: 13,
    client: [managers.manager8],
    last_name: 'GAUTHIER',
    first_name: 'Maxime',
    serial_number: '23456723',
    card_id: 'KLMNBVC2345',
}

export const technician14 = {
    id: 14,
    client: [managers.manager1, managers.manager9],
    last_name: 'PERRIN',
    first_name: 'Alice',
    serial_number: '98765433',
    card_id: 'ERTYUI9876',
}

export const technician15 = {
    id: 15,
    client: [managers.manager10, managers.manager12],
    last_name: 'BENOÎT',
    first_name: 'Thomas',
    serial_number: '78901234',
    card_id: 'TYUIPL4321',
}

export const technician16 = {
    id: 16,
    client: [managers.manager5],
    last_name: 'MARCHAND',
    first_name: 'Léa',
    serial_number: '56783922',
    card_id: 'OPQWERT7865',
}

export const technician17 = {
    id: 17,
    client: [managers.manager14, managers.manager18],
    last_name: 'VIDAL',
    first_name: 'Adrien',
    serial_number: '23456734',
    card_id: 'NMPLKJ4321',
}

export const technician18 = {
    id: 18,
    client: [managers.manager4, managers.manager20],
    last_name: 'LOPEZ',
    first_name: 'Marie',
    serial_number: '87654345',
    card_id: 'ZXCVB76543',
}

export const technician19 = {
    id: 19,
    client: [managers.manager6],
    last_name: 'BLANC',
    first_name: 'Clément',
    serial_number: '34567892',
    card_id: 'QAZXSW1234',
}

export const technician20 = {
    id: 20,
    client: [managers.manager11, managers.manager15],
    last_name: 'RENARD',
    first_name: 'Claire',
    serial_number: '56789023',
    card_id: 'WSXEDC4567',
}

export const technician21 = {
    id: 21,
    client: [managers.manager16],
    last_name: 'MORIN',
    first_name: 'Nathan',
    serial_number: '98765444',
    card_id: 'EDCVFRT6789',
}

export const technician22 = {
    id: 22,
    client: [managers.manager2, managers.manager18],
    last_name: 'NOËL',
    first_name: 'Sarah',
    serial_number: '56789012',
    card_id: 'TYUIOP9876',
}

export const technician23 = {
    id: 23,
    client: [managers.manager7],
    last_name: 'CHARPENTIER',
    first_name: 'Julien',
    serial_number: '12345689',
    card_id: 'GHJKLQ1245',
}

export const technician24 = {
    id: 24,
    client: [managers.manager6, managers.manager11],
    last_name: 'CLÉMENT',
    first_name: 'Elodie',
    serial_number: '87654367',
    card_id: 'NMPOIU5432',
}

export const technician25 = {
    id: 25,
    client: [managers.manager20, managers.manager22],
    last_name: 'AUBERT',
    first_name: 'Louis',
    serial_number: '34567856',
    card_id: 'QAZXSY3245',
}

export const technician26 = {
    id: 26,
    client: [managers.manager3],
    last_name: 'POIRIER',
    first_name: 'Chloé',
    serial_number: '98765489',
    card_id: 'WSXERT9087',
}

export const technician27 = {
    id: 27,
    client: [managers.manager14, managers.manager17],
    last_name: 'RENAUD',
    first_name: 'Gabriel',
    serial_number: '56789034',
    card_id: 'EDCRFV5678',
}

export const technician28 = {
    id: 28,
    client: [managers.manager1],
    last_name: 'BAILLY',
    first_name: 'Léa',
    serial_number: '12345678',
    card_id: 'TGYPOL1234',
}

export const technician29 = {
    id: 29,
    client: [managers.manager4, managers.manager5],
    last_name: 'SCHMITT',
    first_name: 'Bastien',
    serial_number: '87654321',
    card_id: 'UJIKLM6789',
}

export const technician30 = {
    id: 30,
    client: [managers.manager10],
    last_name: 'DUVAL',
    first_name: 'Mélanie',
    serial_number: '34567890',
    card_id: 'OLPKJH2345',
}

export const technician31 = {
    id: 31,
    client: [managers.manager9, managers.manager16],
    last_name: 'ROUSSEL',
    first_name: 'Antoine',
    serial_number: '23456781',
    card_id: 'MNBGTR3456',
}
