import * as managers from 'app/mocks/data/users/managers/list'
import { permissions } from 'app/utils/constants'

export const profile1 = {
    id: 1,
    name: 'Profile1',
    client: managers.manager1,
    permissions: [
        // Machine relations
        { codename: permissions.machines.machineRelations.READ },

        // Planograms
        { codename: permissions.machines.planograms.READ },
        { codename: permissions.machines.planograms.UPDATE },
    ],
    description:
        'Technicien avec des droits de remplissage du planogramme  sur les machines.',
}

export const profile2 = {
    id: 2,
    name: 'Profile2',
    client: managers.manager1,
    description:
        'Utilisateur avec un droit de technicien et de sous-gestionnaire lui permettant de créer des sites, des machines ainsi que de pouvoir étiqueter des produits.',
    permissions: [
        // Clients
        { codename: permissions.park.clients.CREATE },
        { codename: permissions.park.clients.READ },
        { codename: permissions.park.clients.UPDATE },
        { codename: permissions.park.clients.DELETE },

        // All machines
        { codename: permissions.machines.all.CREATE },
        { codename: permissions.machines.all.READ },
        { codename: permissions.machines.all.UPDATE },
        { codename: permissions.machines.all.DELETE },
    ],
}

export const profile3 = {
    id: 3,
    name: 'Profile3',
    client: managers.manager1,
    permissions: [
        // Marketing images
        { codename: permissions.machines.marketingImages.CREATE },
        { codename: permissions.machines.marketingImages.READ },
        { codename: permissions.machines.marketingImages.DELETE },

        // Marketing videos
        { codename: permissions.machines.marketingVideos.CREATE },
        { codename: permissions.machines.marketingVideos.READ },
        { codename: permissions.machines.marketingVideos.DELETE },
    ],
}

export const profile4 = {
    id: 4,
    name: 'Profile4',
    client: managers.manager7,
    permissions: [
        // Sites
        { codename: permissions.park.sites.CREATE },
        { codename: permissions.park.sites.READ },
        { codename: permissions.park.sites.UPDATE },
        { codename: permissions.park.sites.DELETE },

        // Stock
        { codename: permissions.machines.stock.READ },
    ],
}

export const profile5 = {
    id: 5,
    name: 'Profile5',
    client: managers.manager3,
    description: 'Gestionnaire supérieur.',
    permissions: [
        // Managers
        { codename: permissions.users.managers.CREATE },
        { codename: permissions.users.managers.READ },
        { codename: permissions.users.managers.DELETE },

        // Planograms
        { codename: permissions.machines.planograms.UPDATE },

        // Deliveries
        { codename: permissions.operations.deliveries.READ },
    ],
}

export const profile6 = {
    id: 6,
    name: 'Profile6',
    client: managers.manager1,
    description: 'Utilisateur du service compta.',
    permissions: [
        // Konvives
        { codename: permissions.users.konvives.READ },
        { codename: permissions.users.konvives.UPDATE },
    ],
}

export const profile7 = {
    id: 7,
    name: 'Profile7',
    client: managers.manager9,
    permissions: [
        // Profiles
        { codename: permissions.users.profiles.CREATE },
        { codename: permissions.users.profiles.READ },
        { codename: permissions.users.profiles.UPDATE },
        { codename: permissions.users.profiles.DELETE },
    ],
}

export const profile8 = {
    id: 8,
    name: 'Profile8',
    client: managers.manager13,
    permissions: [
        // Profiles
        { codename: permissions.users.profiles.CREATE },
        { codename: permissions.users.profiles.READ },
        { codename: permissions.users.profiles.UPDATE },
        { codename: permissions.users.profiles.DELETE },
    ],
}

export const profile9 = {
    id: 9,
    name: 'Profile9',
    client: managers.manager7,
    description:
        'Service personnalisation des sites clients ainsi que service juridique.',
    permissions: [
        // Allergens
        { codename: permissions.settings.allergens.CREATE },
        { codename: permissions.settings.allergens.READ },
        { codename: permissions.settings.allergens.READ },
        { codename: permissions.settings.allergens.DELETE },

        // Manager settings
        { codename: permissions.settings.managers.CREATE },
        { codename: permissions.settings.managers.READ },
        { codename: permissions.settings.managers.UPDATE },
        { codename: permissions.settings.managers.DELETE },
    ],
}

export const profile10 = {
    id: 10,
    name: 'Profile10',
    client: managers.manager21,
    permissions: [
        // Manager settings
        { codename: permissions.settings.managers.CREATE },
        { codename: permissions.settings.managers.READ },
        { codename: permissions.settings.managers.UPDATE },
        { codename: permissions.settings.managers.DELETE },

        // Product categories settings
        { codename: permissions.settings.productCategories.CREATE },
        { codename: permissions.settings.productCategories.READ },
        { codename: permissions.settings.productCategories.READ },
        { codename: permissions.settings.productCategories.DELETE },
    ],
}

export const profile11 = {
    id: 11,
    name: 'Profile11',
    client: managers.manager5,
    description: 'Service personnalisation et création produits 2eme étage.',
    permissions: [
        // Manager settings
        { codename: permissions.settings.managers.CREATE },
        { codename: permissions.settings.managers.READ },
        { codename: permissions.settings.managers.UPDATE },
        { codename: permissions.settings.managers.DELETE },

        // Subcategory products settings
        { codename: permissions.settings.subcategoryProducts.CREATE },
        { codename: permissions.settings.subcategoryProducts.READ },
        { codename: permissions.settings.subcategoryProducts.UPDATE },
        { codename: permissions.settings.subcategoryProducts.DELETE },
    ],
}

export const profile12 = {
    id: 12,
    name: 'Profile12',
    client: managers.manager18,
    permissions: [
        // Manager settings
        { codename: permissions.settings.managers.CREATE },
        { codename: permissions.settings.managers.READ },
        { codename: permissions.settings.managers.UPDATE },
        { codename: permissions.settings.managers.DELETE },

        // Email settings
        { codename: permissions.settings.emails.CREATE },
        { codename: permissions.settings.emails.READ },
        { codename: permissions.settings.emails.UPDATE },

        // Personalisation settings
        { codename: permissions.settings.personalisation.UPDATE },
    ],
}

export const profile13 = {
    id: 13,
    name: 'Profile13',
    client: managers.manager3,
    permissions: [
        // Disputes
        { codename: permissions.transactions.disputes.READ },

        // Products disputed
        { codename: permissions.transactions.disputedProducts.READ },

        // Refills
        { codename: permissions.transactions.refills.READ },

        // Refunds
        { codename: permissions.transactions.refunds.READ },

        // Sales
        { codename: permissions.transactions.sales.READ },
    ],
}
