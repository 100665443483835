import { http, HttpResponse } from 'msw'
import { allDeliveryMen } from 'app/mocks/data/users/deliveryMen'

import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'

export const getAllDeliveryMen = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.deliveryMen.LIST}`,
    async ({ request }) => {
        const url = new URL(request.url)

        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        const managersFilter = url.searchParams.get('client__id__in')
        const lastNameFilter = url.searchParams.get('last_name__icontains')
        const firstNameFilter = url.searchParams.get('first_name__icontains')
        const cardNumberFilter = url.searchParams.get('card_id__icontains')
        const mifareCodeFilter = url.searchParams.get(
            'serial_number__icontains',
        )

        let allDeliveryMenFiltered = allDeliveryMen

        // Manager filter
        if (managersFilter) {
            const managersIds = queryIntoArrayNumber(managersFilter)

            allDeliveryMenFiltered = allDeliveryMenFiltered.filter(
                (deliveryMan) =>
                    deliveryMan.client.some((manager) =>
                        managersIds.includes(manager.id),
                    ),
            )
        }

        // Last name filter
        if (lastNameFilter) {
            allDeliveryMenFiltered = allDeliveryMenFiltered.filter(
                (deliveryMan) =>
                    deliveryMan.last_name
                        .toLowerCase()
                        .includes(lastNameFilter.toLowerCase()),
            )
        }

        // First name filter
        if (firstNameFilter) {
            allDeliveryMenFiltered = allDeliveryMenFiltered.filter(
                (deliveryMan) =>
                    deliveryMan.first_name
                        .toLowerCase()
                        .includes(firstNameFilter.toLowerCase()),
            )
        }

        // Card number filter
        if (cardNumberFilter) {
            allDeliveryMenFiltered = allDeliveryMenFiltered.filter(
                (deliveryMan) =>
                    deliveryMan.card_id
                        .toLowerCase()
                        .includes(cardNumberFilter.toLowerCase()),
            )
        }

        // MIFARE code filter
        if (mifareCodeFilter) {
            allDeliveryMenFiltered = allDeliveryMenFiltered.filter(
                (deliveryMan) =>
                    deliveryMan.serial_number
                        .toLowerCase()
                        .includes(mifareCodeFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allDeliveryMenFiltered.length > limit
                ? offset + limit
                : allDeliveryMenFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allDeliveryMenFiltered.slice(Number(offset), limitArray)
                : allDeliveryMenFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allDeliveryMenFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allDeliveryMenFiltered.length,
            next,
            results: results,
        })
    },
)
