import { createDeliveryMan } from './createDeliveryMan'
import { deleteDeliveryMan } from './deleteDeliveryMan'
import { getAllDeliveryMen } from './getAllDeliveryMen'
import { getDeliveryMan } from './getDeliveryMan'
import { updateDeliveryMan } from './updateDeliveryMan'

export const deliveryMen = [
    createDeliveryMan,
    deleteDeliveryMan,
    getAllDeliveryMen,
    getDeliveryMan,
    updateDeliveryMan,
]
