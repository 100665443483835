import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'
import { allTechnicians } from 'app/mocks/data/users/technicians'

export const deleteTechnician = http.delete(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.technicians.DETAILS}`,
    ({ params }) => {
        const { id } = params

        const technicianFound = allTechnicians.find(
            (technician) => technician.id.toString() === id,
        )
        // Not found
        if (!technicianFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return new HttpResponse(null, {
            status: StatusCodes.NO_CONTENT,
        })
    },
)
